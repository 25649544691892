.snackbar {
  visibility: hidden;
  min-width: 30%;
  height: 7%;
  /* margin-left: -250px; */
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  z-index: 1000000;
  bottom: 30px;
  font-size: 1rem;
}

.snackbar.fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s ease-in 0s, opacity 300ms;
}

.snackbar.fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s ease-out 300ms, opacity 300ms
}