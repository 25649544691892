.navbar-vertical.navbar-expand-xs.fixed-start {
    z-index: 0;
}

.bg-green {
    background-color: green;
  }
  
  .bg-red {
    background-color: red;
  }