.digit {
    width: 30px;
    height: 50px;
    /* background-color: lighten($BaseBG, 5%); */
    border: none;
    line-height: 50px;
    text-align: center;
    font-size: 24px;
    font-family: 'Raleway', sans-serif;
    font-weight: 200;
    /* color: white; */
    margin: 0 2px;
}