.modals {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  border: '1px solid red';
  display: flex;
  justify-content: center;
  overflow: auto;
  z-index:99
}

.modals-mains {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -99;
}

.modals-section {
margin-top: 4rem;
}

.display-none {
  display: none;
}