input[type=text] {
  width: 100%;
  padding: 12px 20px;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=number] {
  width: 100%;
  padding: 12px 20px;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=datetime-local] {
  width: 100%;
  padding: 9px 10px;
  margin-top: -.3rem;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type=date] { 
  width: 100%; 
  padding: 11px 16px; 
  display: block; 
  border: 1px solid #ccc; 
  border-radius: 4px; 
  font-size: 0.8rem; 
}

select {
  width: 100%;
  padding: 12px 20px;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  width: 100%;
  padding: 12px 20px;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 0.4rem !important;
}