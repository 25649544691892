.tab {
    border: none;
}

.tab.active {
  background-color: white;
  font-weight: bold;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 5px;
}
.grid-item {
  padding: 5px;
}